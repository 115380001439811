@tailwind base;
@tailwind components;
@tailwind utilities;

textarea {
    resize: none;
}

@font-face {
    font-family: "Raleway";
    src: URL("./assets/fonts/Raleway-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Raleway";
    src: URL("./assets/fonts/Raleway-ExtraBold.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Raleway";
    src: URL("./assets/fonts/Raleway-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Raleway";
    src: URL("./assets/fonts/Raleway-Black.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Raleway";
    src: URL("./assets/fonts/Raleway-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

html {
    height: 100%;
}

body {
    margin: 0;
    height: 100%;
    font-family: "Raleway";
    @apply bg-gray-1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

code {
    font-family:
        source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.width-80 {
    width: 80px;
    max-width: 80px;
    min-width: 80px;
}

.width-500 {
    width: 500px;
    max-width: 500px;
    min-width: 500px;
}

#root {
    height: 100%;
}

.no-margin {
    margin: 0;
}

#wrapper {
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
}

#profile-editor-container {
    margin: 0 auto;
}

#content {
    padding: 10px 10px 10px 0;
}

.profile-viewer-content {
    margin: 0 auto;
    padding: 10px;
    max-width: 800px;
    height: 100%;
}

.profile-viewer-content-question {
    margin: 0 auto;
    max-width: 800px;
    height: 100%;
}

.profile-viewer-content-add-link {
    max-width: 800px;
    min-width: 800px;
    height: 100%;
    min-height: 100%;
}

.profile-editor-content {
    margin: 0 auto;
    padding: 10px 10px 10px 0;
}

@media print {
    textarea {
        resize: none;
    }

    body,
    html,
    #wrapper,
    .scrollable-page,
    .ps,
    .panel {
        height: 100% !important;
        width: 100% !important;
        display: inline-block;
        overflow: visible;
    }

    .page-break {
        page-break-after: always;
        background-color: #2196f3 !important;
    }

    #test-box {
        background-color: #2196f3 !important;
        color: red;
    }

    #personal-details-container {
        max-width: 300px;
    }
    #company-logo {
        background-image: url("assets/onegroup.png");
        background-repeat: no-repeat;
        border: "1px solid blue";
        position: absolute;
        top: 0;
        right: 5px;
        width: 180px;
        height: 50px;
        background-size: contain;
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
    }
}

.auth-input-container {
    width: 400px;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-items: center;
}

@media only screen and (max-width: 3000px) {
    .profile-viewer-content {
        width: 50vw;
    }

    .profile-editor-content {
        width: 60vw;
    }

    .profile-viewer-content-question {
        width: 50vw;
    }
}

@media only screen and (max-width: 1500px) {
    .profile-viewer-content {
        width: 80vw;
    }

    .profile-editor-content {
        width: 82vw;
    }

    .profile-viewer-content-question {
        width: 82vw;
    }
}

@media only screen and (max-width: 1080px) {
    .profile-viewer-content {
        width: 80vw;
    }

    .profile-editor-content {
        width: 80vw;
    }

    .profile-viewer-content-question {
        width: 80vw;
    }
}

@media only screen and (max-width: 700px) {
    .profile-viewer-content {
        width: 100vw;
    }

    .profile-editor-content {
        width: 80vw;
    }

    .profile-viewer-content-question {
        width: 80vw;
    }
}

#profile-viewer-toolbar {
    position: relative;
    float: left;
    width: 450px;
}

#left-content {
    max-width: 600px;
}

#right-content {
    width: 320px;
}

.vk-profile-picture {
    max-width: 110px;
    width: 110px;
    height: 110px;
    float: left;
}

.vk-profile-picture-image {
    max-width: 110px;
    height: 110px;
}

#personal-info-content {
    border: 1px solid #000;
    overflow: hidden;
    max-height: 100px;
}

#vk-crop-tool-container {
    background-color: "red";
}

#vk-crop-tool-preview {
    margin-left: 250px;
    text-align: center;
}
#vk-cropped-image-preview {
    width: 300px;
    height: 300px;
    margin: auto;
    border-radius: 150px;
}

#vk-crop-tool-controls {
    height: 100%;
    width: 250px;
    background-color: white;
    position: absolute;
    z-index: 999;
    padding-left: 15px;
    padding-right: 15px;
    top: 0;
    left: 0;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: #2196f3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.info-box-hidden {
    opacity: 0;
    background-color: #fff;
    border: 1px solid #d3d3d3;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: 14px;
}

@keyframes animate-show-info-box {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes animate-hide-info-box {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

#page-header {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    justify-content: space-between;
    background-color: #e8eaed;
    color: #202124;
}

.info-box-show-animation {
    animation-name: animate-show-info-box;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

.info-box-hide-animation {
    animation-name: animate-hide-info-box;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

#personal-details-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#name-title-container {
    display: flex;
    justify-content: center;
    min-height: 100%;
    flex-direction: column;
    padding-left: 20px;
}

@media only screen and (min-width: 701px) {
    #personal-details-container {
        display: flex;
        flex-direction: row;
        text-align: left;
        justify-content: flex-start;
        align-items: center;
    }

    #personal-details-container h3 {
        font-size: 26px;
        font-weight: bold;
        margin: 0;
    }

    #personal-details-container h4 {
        font-size: 20px;
        font-weight: bold;
        margin: 0;
    }

    .skill-block-item-row {
        display: flex;
        flex: 1;
        flex-direction: row;
    }
}

@media only screen and (max-width: 800px) {
    #personal-details-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    #personal-details-container h3 {
        font-size: 5vw;
        font-weight: bold;
        margin: 10px 0 0 0;
    }

    #personal-details-container h4 {
        font-size: 4vw;
        font-weight: bold;
        margin: 5px 0 0 0;
    }

    .skill-block-item-row {
        display: flex;
        flex: 1;
        flex-direction: column;
    }

    #page-header {
        flex-direction: column;
    }

    #name-title-container {
        align-items: center;
    }

    #name-title-container {
        align-items: center;
        padding-left: 0;
    }
}

.input-help-label ul {
    padding-inline-start: 20px;
}

.input-help-label li {
    margin-top: 5px;
}

.menu-link-button:hover {
    text-decoration: underline;
}

#sign-up-bottom-border {
    position: absolute;
    width: 100%;
    height: 80px;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

#sign-up-bottom-border-content {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
}

#landing-left-column {
    padding-right: 150px;
    justify-content: flex-end;
}
#landing-right-column {
    padding-left: 150px;
    justify-content: flex-start;
}
#landing-top-row {
    flex-direction: row;
}

@media only screen and (max-width: 800px) {
    #sign-up-bottom-border {
        height: 150px;
        justify-content: center;
        flex-direction: column;
    }

    #sign-up-bottom-border-content {
        justify-content: center;
        flex-direction: column;
    }

    #landing-left-column {
        justify-content: center;
    }

    #landing-right-column {
        justify-content: center;
    }

    #landing-top-row {
        flex-direction: column;
    }
}

.autofill-suggestion {
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
    background-color: #fff;
    color: "black";
}

.autofill-suggestion:hover {
    cursor: pointer;
    background-color: #e3e3e3;
}

.autofill-suggestion-dark-mode {
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
    background-color: #000;
    color: #fff;
}

.autofill-suggestion-dark-mode:hover {
    cursor: pointer;
    background-color: #ccc;
}

#tired-of-this-teaser {
    visibility: visible;
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100%;
    left: 180;
}

#landing-profile-mockup {
    border: 8px solid #202124;
    display: flex;
    flex-direction: column;
    min-height: 1000px;
    max-height: 1020px;
    width: 510px;
    margin-top: 150px;
    margin-bottom: 100px;
}

.landing-profile-mockup-container {
    padding: 60px;
}

#landing-profile-mockup p {
    font-family: "Raleway", sans-serif;
    font-weight: normal;
    font-size: 16px;
}

#landing-profile-mockup h1 {
    font-family: "Raleway", sans-serif;
    font-weight: 800;
    font-size: 58px;
    color: #e8eaed;
}

#landing-button-hero-container {
    flex: 1;
    width: 300px;
    margin-top: 60px;
    display: flex;
    flex-direction: row;
}

#landing-sign-in-button {
    width: 100%;
    margin-left: 25px;
    display: flex;
    flex: 1;
}

#tired-of-this-section {
    visibility: visible;
}

#landing-profile-mockup-header {
    margin-top: 100px;
}

@media only screen and (max-width: 1300px) {
    #landing-left-column {
        padding-right: 80px;
    }
    #landing-right-column {
        padding-left: 80px;
    }
}

@media only screen and (max-width: 1000px) {
    #landing-left-column {
        padding-right: 0px;
    }
    #landing-right-column {
        padding-left: 0px;
    }
}

.landing-center-wrapper {
    width: 1440px;
    height: 100%;
    margin: 0 auto;
}

@media only screen and (max-width: 1440px) {
    .landing-center-wrapper {
        width: 1200px;
        height: 100%;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 1200px) {
    .landing-center-wrapper {
        width: 1000px;
        height: 100%;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 1000px) {
    .landing-center-wrapper {
        width: 800px;
        height: 100%;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 800px) {
    .landing-center-wrapper {
        width: 100%;
        height: 100%;
        margin: 0 auto;
    }

    #tired-of-this-teaser {
        display: none;
    }

    #landing-profile-mockup {
        max-width: 80%;
    }

    #landing-profile-mockup {
        border: 8px solid #202124;
        display: flex;
        flex-direction: column;
        min-height: 1000px;
        width: 510px;
        margin-top: 60px;
        margin-bottom: 10px;
    }

    .landing-profile-mockup-container {
        padding: 20px;
        flex: 1;
    }

    #landing-button-hero-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 160px;
    }

    #landing-sign-in-button {
        margin-left: 0;
        flex-direction: column;
        flex: 1;
        display: flex;
        margin-top: 20px;
    }

    #tired-of-this-section {
        display: none;
    }

    #landing-profile-mockup-header {
        margin-top: 0px;
    }

    .auth-input-container {
        width: 80%;
        height: 250px;
        display: flex;
        flex-direction: column;
        justify-items: center;
    }
}

#tired-of-this-span {
    font-family: "Raleway", sans-serif;
    font-size: 30px;
    font-weight: bold;
}

#hero h1 {
    font-size: 52px;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
}

#hero h3 {
    font-size: 20px;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
}

.landing-profile-title {
    font-size: 24px;
    font-family: "Raleway", sans-serif;
    font-weight: 900;
    color: #202124;
}

@media only screen and (max-width: 600px) {
    #hero h1 {
        font-size: 2em;
    }
    #hero h3 {
        font-size: 1em;
    }
}

.mega-header {
    font-family: "Raleway", sans-serif;
    font-size: 80px;
    text-align: center;
    font-weight: bold;
}

#site-navigation {
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
}

#site-navigation span:hover {
    cursor: pointer;
    font-family: "Raleway", sans-serif;
    font-weight: 800;
}

.example-enter {
    -webkit-transition: max-height 300ms;
    -moz-transition: max-height 300ms;
    -ms-transition: max-height 300ms;
    -o-transition: max-height 300ms;
    transition: max-height 300ms;
    background: red;
    overflow: hidden;
    max-height: 200px;
}
.example-enter-active {
    -webkit-transition: max-height 300ms;
    -moz-transition: max-height 300ms;
    -ms-transition: max-height 300ms;
    -o-transition: max-height 300ms;
    transition: max-height 300ms;
    background: red;
    overflow: hidden;
    max-height: 200px;
}
.example-exit {
    -webkit-transition: max-height 1s;
    -moz-transition: max-height 1s;
    -ms-transition: max-height 1s;
    -o-transition: max-height 1s;
    transition: max-height 1s;
    background: #e5feff;
    overflow: hidden;
    max-height: 0;
}
.example-exit-active {
    transform: scaleY(0);
    transition: transform 300ms ease;
}

.site-navigation-show-animation {
    animation-name: animate-show-site-navigation;
    animation-duration: 500ms;
    animation-fill-mode: forwards;
}

#page-header-logo {
    max-width: 160px;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
}

@keyframes animate-show-site-navigation {
    from {
        max-height: 0px;
    }
    to {
        max-height: 200px;
    }
}

.site-navigation-hide-animation {
    animation-name: animate-hide-site-navigation;
    animation-duration: 500ms;
    animation-fill-mode: forwards;
}

@keyframes animate-hide-site-navigation {
    from {
        max-height: 200px;
    }
    to {
        max-height: 0px;
    }
}

.section-header {
    font: "Raleway", sans-serif;
    font-size: 12px;
    font-weight: normal;
    text-transform: uppercase;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 2px;
    color: #93949a;
    text-align: right;
}

::-webkit-scrollbar {
    display: none;
}

#company-logo {
    background-image: url("assets/onegroup.png");
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    right: 5px;
    width: 180px;
    height: 50px;
    background-size: contain;
}

.text-sm li::marker {
    @apply text-[10px];
}

[data-vaul-drawer-content] {
    pointer-events: auto !important;
}

.cursor-grab {
    touch-action: none;
}
