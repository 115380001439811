.vk-textarea {
  width: 100%;
  background-color: transparent;
  border-width: 0;
  font-size: 16px;
  outline: none;
  font-family: "Raleway", sans-serif;
  font-weight: normal;
  height: auto;
  overflow: hidden;
  height: 30px;
  padding: 0;
  color:black;
  opacity: 1;
}


.vk-textarea::placeholder {
  color: #9b9a9a;  
}

.vk-textarea-placeholder {
  color: #e3e3e3;
}

.vk-bottom-border-line {
  height: 1px;
  background-color: #202124;
  width: 0;
}

@keyframes animate-bottom-line-focus {
  to {
    width: 100%;
  }
}

@keyframes animate-bottom-line-blur {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}

.vk-bottom-border-line-animate {
  animation-name: animate-bottom-line-focus;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.vk-bottom-border-line-animate-blur {
  animation-name: animate-bottom-line-blur;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
