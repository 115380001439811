.vk-input {
    font-size: 38px;
    padding:0;
    border-image: none;
    border-width: 0;
    outline: none;    
  }
  
  .vk-bottom-border-line {
    height: 1px;
    background-color: black;
    width: 0;
  }
  
  @keyframes animate-bottom-line-focus {
    to {width: 300px;}
  }
  
  @keyframes animate-bottom-line-blur {
      from {width: 300px;}
    to {width: 0;}
  }
  
  .vk-bottom-border-line-animate {
    animation-name: animate-bottom-line-focus;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }
  
  .vk-bottom-border-line-animate-blur {
    animation-name: animate-bottom-line-blur;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }
  
  ::placeholder {
    color: #7A7978;
  }

  .vk-input-container {
    float:left;
    border:1px solid #000;
  }